import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MarkdownContent,
  GroupedList,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Header, Grid, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import SecondaryLayout from "../components/SecondaryLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class TeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
      allFishermanBusinessTeamGroup,
    } = data;

    return (
      <SecondaryLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout
          hero={
            <HeroElement
              header={
                <Header
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "stylists_header",
                    defaultValue: "Stylists",
                  })}
                />
              }
              tagline={
                <MarkdownContent
                  className={"about-team-description"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "stylists_description",
                  })}
                />
              }
              images={[
                "https://fisherman.gumlet.io/public/47372a28-2bba-411e-b98b-6a79e266eae9/George-Salon-Team-Header.jpg?width=1250",
              ]}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              carouselOptions={{ autoScrollTime: 10000 }}
            />
          }
          className={"team-page"}
        >
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"team-content-container"}
          >
            <Grid.Column width={16}>
              <GroupedList
                items={allFishermanBusinessTeam.nodes}
                groupBy={"teams.name"}
                childItemsKey={"iterator"}
                defaultGroupName={"Our Team"}
                groupOrderOptions={{
                  defaultGroupPosition: "start",
                  orderKey: "name",
                  order: allFishermanBusinessTeamGroup.nodes,
                }}
                itemComponent={
                  <MultipleComponentIterator
                    components={[
                      {
                        component: (
                          <SlideReveal
                            className={"ui card team-member"}
                            fade={true}
                            direction={"up"}
                            triggerOnce={true}
                          />
                        ),
                        propMap: { key: "_id" },
                        children: [
                          {
                            component: (
                              <BackgroundImageTeamMember
                                useModal={false}
                                as={InternalLink}
                              />
                            ),
                            propMap: {
                              "teamMember.name": "name",
                              "teamMember.role": "role",
                              "teamMember.photo": "gatsbyImage",
                              "teamMember.description": "description",
                              "teamMember.email": "email",
                              "teamMember.phone": "phone",
                              "teamMember.locations": "locations",
                              "teamMember.hours": "hours",
                              "teamMember.social": "socialMedia",
                              to: createTeamMemberSlug,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                }
                groupHeaderComponent={<Header as={"h2"} />}
                groupContainerComponent={
                  <Card.Group
                    itemsPerRow={3}
                    className={"stylists"}
                    stackable={true}
                  />
                }
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </SecondaryLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Team" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        teams {
          _id
          name
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessTeamGroup(sort: { order: ASC, fields: order }) {
      nodes {
        name
      }
    }
  }
`;
